<template>
  <form class="form-cms mt-1">
    <div class="form-control">
      <label for="characteristics-ingredient">Característica:</label>
      <textarea
        rows="3"
        name="characteristics-ingredient"
        placeholder="Característica"
      />
    </div>
    <div class="buttons gap-0-5">
      <button class="btn btn-primary btn-medium">
        <i class="fas fa-pen-alt"></i> Actualizar
      </button>
      <button class="btn btn-secondary btn-medium">
        <i class="far fa-trash-alt"></i> Eliminar
      </button>
    </div>
  </form>
</template>
